export const saveToLocalStorage = (key, data) => {
	try {
		localStorage.setItem(key, JSON.stringify(data));
	} catch (error) {
		console.error("Error saving to localStorage:", error);
	}
};

export const loadFromLocalStorage = (key) => {
	try {
		const data = localStorage.getItem(key);
		return data ? JSON.parse(data) : null;
	} catch (error) {
		console.error("Error loading from localStorage:", error);
		return null;
	}
};

export const removeFromLocalStorage = (key) => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		console.error("Error removing from localStorage:", error);
	}
};

export const getAuthorizationListVersion = () => {
	try {
		const storedData = localStorage.getItem("locallist");

		console.log(storedData);
		if (storedData) {
			const { listVersion } = JSON.parse(storedData);
			return listVersion;
		} else {
			return null;
		}
	} catch (error) {
		console.error("Error loading data from localStorage:", error);
		return null;
	}
};
